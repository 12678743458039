<template>
  <div
    class="is-flex is-flex-direction-column is-justify-content-space-between"
  >
    <Header v-if="isLoggedIn" />
    <div class="logged-content">
      <div class="container">
        <slot></slot>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { Footer } from '@/components/Footer';
import { Header } from '@/components/Header';
import { loginStorage } from '@/store';

export default {
  name: 'Wrapper',
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      isLoggedIn: loginStorage.isUserLogged(),
    };
  },
};
</script>

<style lang="scss">
.logged-content {
  margin-bottom: 100px;
  min-height: 100vh;
}
</style>
