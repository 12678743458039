<template>
  <div>
    <b-image :src="require('@/assets/image/color_bar.png')" alt="Color bar" />
    <header :class="classes">
      <div class="logo-container">
        <router-link to="/home">
          <b-image
            class="logo"
            :src="require('@/assets/image/kamino_logo_black.svg')"
            alt="Kamino logo"
          />
        </router-link>
      </div>
      <div class="is-flex is-align-items-center">
        <b-button type="is-link" icon-left="logout" @click="onLogout">
          Sair
        </b-button>
      </div>
    </header>
  </div>
</template>

<script>
import { authService } from '@/services/auth';

export default {
  name: 'Header',
  props: {
    title: {
      type: String,
      default: 'Kamino',
    },
    subtitle: {
      type: String,
    },
  },
  computed: {
    classes() {
      return `section has-background-white has-shadow is-flex is-justify-content-space-between`;
    },
  },
  methods: {
    onLogout() {
      this.$buefy.dialog.confirm({
        confirmText: 'Sair',
        cancelText: 'Cancelar',
        message: 'Deseja realmente sair?',
        type: 'is-link',
        onConfirm: () => {
          authService.logout();
          this.$router.push('/login');
        },
      });
    },
  },
};
</script>

<style lang="scss">
.logo-container {
  width: 20%;

  @media (max-width: 767px) {
    width: 37%;
  }
}

.logo {
  max-width: 224px;
}
</style>
