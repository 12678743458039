<template>
  <footer class="footer footer-fixed has-shadow">
    <div class="content has-text-centered">
      <p>
        Kamino © 2022
        <span>&nbsp; | &nbsp;</span>
        <a
          class="is-underlined"
          href="https://kamino.com.br/general-terms-and-conditions/"
          target="_blank"
          >Termos e Condições de Uso</a
        >
        <span>&nbsp; | &nbsp;</span>
        <a
          class="is-underlined"
          href="https://kamino.com.br/privacy-policy/"
          target="_blank"
          >Política de Privacidade</a
        >
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style lang="scss">
.footer-fixed {
  bottom: 0px;
  left: 0px;
  margin-bottom: 0px;
  right: 0px;
}
</style>
